import { Link } from "gatsby"
import parse from "html-react-parser"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import Slide from "../../utility/slide_content"
import SVGIcon from "../SVGIcon"
import "./Page.scss"

const Page = props => {
  return (
    <div className="Page">
      <Container id={props.to === "section" ? props.id : ""}>
        {props.sectionTitle && (
          <>
            <Slide delay="200">
              {props.sectionTitle && props.sectionTitleLink ? (
                <div className="mb-5 align-items-center d-flex">
                  <Link
                    className="title-link d-flex align-items-center"
                    to={props.sectionTitleLink}
                  >
                    {props.sectionTitle}
                    <SVGIcon
                      name="hypertext-arrow-right"
                      className="arrow-right"
                    />
                  </Link>
                  <div className="hr-line"></div>
                </div>
              ) : (
                <div className="mb-5 align-items-center d-flex">
                  <h2 className="section-title d-flex align-items-center m-0">
                    {props.sectionTitle}
                    <SVGIcon
                      name="hypertext-arrow-right"
                      className="arrow-right"
                    />
                  </h2>
                  <div className="hr-line"></div>
                </div>
              )}
            </Slide>
          </>
        )}

        {props.contents.map((el, idx2) => {
          return (
            <div
              className="contents"
              key={idx2}
              id={props.to === "heading" ? idx2 : ""}
              style={
                typeof props.activeTab === "number"
                  ? {
                      display: props.activeTab === idx2 ? "block" : "none",
                    }
                  : {}
              }
            >
              <>
                {el.tag && (
                  <div className="p16">
                    <Slide delay="200">
                      <p className="active">{el.tag}</p>
                    </Slide>
                  </div>
                )}
                {el.heading && (
                  <Slide delay="200">
                    <h2 className="title">{el.heading}</h2>
                  </Slide>
                )}

                {el.content && (
                  <>
                    <Slide delay="200">
                      <div className="description-section">
                        <div className="p16 text-grey">
                          <p className="description">
                            {parse(el.content.html)}
                          </p>
                        </div>
                      </div>
                    </Slide>
                  </>
                )}

                {el?.detailContent.length > 0 && (
                  <Slide delay="200">
                    <Row className="Content-box-row">
                      {el.detailContent.map((content, idx) => {
                        return (
                          <Col key={idx} lg={6} sm={12} key={idx}>
                            <div className="Content-box">
                              <div className="header">
                                {el.detailIcon && (
                                  <img
                                    src={el?.detailIcon[idx]?.url}
                                    alt={el?.detailIcon[idx]?.url}
                                    className="icon"
                                  />
                                )}
                                <h3 className="fw-bold font-size-16 m-0">
                                  {el.detailHeading[idx]}
                                </h3>
                              </div>

                              <div className="content p14 text-grey">
                                <p>{content}</p>
                              </div>
                            </div>
                          </Col>
                        )
                      })}
                    </Row>
                  </Slide>
                )}

                {el.stripContent && (
                  <Slide delay="200">
                    <div className="strip p16 a16">
                      {parse(el.stripContent.html)}
                    </div>
                  </Slide>
                )}
              </>
            </div>
          )
        })}
      </Container>
    </div>
  )
}

export default Page
