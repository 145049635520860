import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { Col, Container, Row } from "react-bootstrap"
import { Link } from "react-scroll"
import CTA from "../components/common/CTA"
import { InPageDropDown } from "../components/common/dropdown-component/makeDropdown"
import Frame from "../components/common/frame"
import Hero from "../components/common/Hero/Hero"
import Page from "../components/common/Page/Page"
import Navigation from "../components/navigation"
import CTAThankYou from "../components/utility/cta_thank_you"
import Slide from "../components/utility/slide_content"
import "../styles/features.scss"

function FeatureGlossary({ pageContext }) {
  const { feature } = pageContext

  return (
    <>
      <StaticQuery
        query={graphql`
          query contentFeatureGlossary {
            SuperOps {
              pages(where: { title: "Feature Glossary" }) {
                title
                navigationBlock {
                  name
                  slug
                  isDropdown
                }
              }
            }
            site {
              siteMetadata {
                url
                twitterHandle
              }
            }
          }
        `}
        render={data => (
          <div className="feature-glossary">
            {data.SuperOps.pages.map(page => {
              const { navigationBlock } = page
              return (
                <>
                  <Frame
                    seo={feature.seo}
                  >
                    <header>
                      <Navigation
                        links={navigationBlock}
                        page="About us"
                        color={feature.heroBgColor}
                      />
                    </header>
                    <div>
                      <section>
                        <Hero
                          background={feature.heroBgColor}
                          heading={feature.heroHeading}
                          maxHeadingWidth="500px"
                          maxDescriptionWidth="500px"
                          description={feature.heroExcerpt}
                          primaryButtonText="GET STARTED FOR FREE"
                          primaryButtonLink="/signup"
                          secondaryButtonText="BOOK A DEMO"
                          secondaryButtonLink="/demo"
                          image={feature.heroImage.url}
                          maxImageWidth="500px"
                          marginBottom="40px"
                        />
                      </section>

                      <section className="main-section">
                        <Container>
                          <InPageDropDown
                            links={feature.pageComponents.map(
                              fgl => fgl.sectionName
                            )}
                          />
                          <Row className="justify-content-between">
                            <Col lg={3}>
                              <div className="fe-gl-nav-dsk sticky-top d-dsk">
                                {feature.pageComponents.map((fgl, fglIndex) => {
                                  return (
                                    <Link
                                      to={fglIndex}
                                      spy={true}
                                      offset={-550}
                                      smooth={true}
                                      duration={500}
                                      activeClass="active"
                                    >
                                      <div className="fgl-nav">
                                        <p>{fgl.sectionName}</p>
                                        <div className="at-line" />
                                      </div>
                                    </Link>
                                  )
                                })}
                              </div>
                            </Col>

                            <Col lg={8}>
                              {feature.pageComponents.map((el, idx) => {
                                return (
                                  <>
                                    <Page
                                      sectionTitle={el.sectionName}
                                      sectionTitleLink={el.sectionLink}
                                      contents={el.pageContents}
                                      key={idx}
                                      id={idx}
                                      to="section"
                                    />
                                  </>
                                )
                              })}
                            </Col>
                          </Row>
                        </Container>
                      </section>
                      <section className="feature-CTA">
                        <Slide delay="200">
                          <CTA data={[feature.ctaBox]} />
                        </Slide>
                      </section>
                    </div>
                  </Frame>
                  <CTAThankYou />
                </>
              )
            })}
          </div>
        )}
      />
    </>
  )
}

export default FeatureGlossary
